import { CalendarState } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';

export type AgendaSlot = {
  id: number;
  formattedTime: string;
  ServiceName: string;
  duration?: string;
  location?: string;
  staffMember?: string;
  price?: string;
  spotsLeft?: string;
  policy?: string;
  cta?: string;
};

export type AgendaSlotsViewModel = AgendaSlot[];
export type EnrichedSlotAvailability = SlotAvailability & { id: number };

type AgendaSlotsViewModelParams = ViewModelFactoryParams<
  CalendarState,
  CalendarContext
> & {
  slots: EnrichedSlotAvailability[];
};

export const enrichSlotAvailability = (
  slotsAvailability?: SlotAvailability[],
) => {
  return (
    slotsAvailability?.map((slotAvailability, index) => ({
      ...slotAvailability,
      id: index,
    })) || []
  );
};

export const memoizedAgendaSlotsViewModel: MemoizedViewModalFactory<AgendaSlotsViewModel> =
  {
    dependencies: {},
  };

export function createAgendaSlotsViewModel({
  state,
  context,
  slots,
}: AgendaSlotsViewModelParams): AgendaSlotsViewModel {
  return (
    slots?.map((slot) => {
      return {
        id: slot.id,
        formattedTime: '',
        ServiceName: '',
      };
    }) || []
  );
}

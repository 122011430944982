import { widgetDefaults } from './consts';
import { ControllerFlowAPI, VisitorLogger } from '@wix/yoshi-flow-editor';
import {
  bookingsCalendarClick,
  bookingsCalendarTimePickerLoad,
  bookingsCalendarDatePickerLoad,
  bookingsCalendarErrorMessages,
  bookingsCalendarBookingDetailsLoad,
  bookingsContactInfoSaveSuccess,
  bookingsPaymentMethodSelectionNextClicked,
  bookingsLoginUserAccountAppointmentsRescheduleSuccess,
  bookingsCalendarPageLoaded,
  bookingsCalendarFiltersLoad,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { CalendarState } from '../../components/BookingCalendar/controller';
import {
  WixOOISDKAdapter,
  BookingsQueryParams,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { BusinessInfo } from '@wix/bookings-uou-types';

export interface CalendarBiLogger extends VisitorLogger {
  update: (state: CalendarState) => void;
  bookingsCalendarClick: typeof bookingsCalendarClick;
  bookingsCalendarPageLoaded: typeof bookingsCalendarPageLoaded;
  bookingsCalendarFiltersLoad: typeof bookingsCalendarFiltersLoad;
  bookingsCalendarTimePickerLoad: typeof bookingsCalendarTimePickerLoad;
  bookingsCalendarDatePickerLoad: typeof bookingsCalendarDatePickerLoad;
  bookingsCalendarErrorMessages: typeof bookingsCalendarErrorMessages;
  bookingsCalendarBookingDetailsLoad: typeof bookingsCalendarBookingDetailsLoad;
  bookingsContactInfoSaveSuccess: typeof bookingsContactInfoSaveSuccess;
  bookingsPaymentMethodSelectionNextClicked: typeof bookingsPaymentMethodSelectionNextClicked;
  bookingsLoginUserAccountAppointmentsRescheduleSuccess: typeof bookingsLoginUserAccountAppointmentsRescheduleSuccess;
}

export function createCalendarBiLogger(
  flowAPI: ControllerFlowAPI,
  initialState: CalendarState,
  wixSdkAdapter: WixOOISDKAdapter,
  settings: any,
  settingsParams: any,
  businessInfo?: BusinessInfo,
): CalendarBiLogger {
  const { bi: viewerBiLogger } = flowAPI;
  const isRefactorCalendarStateEnabled = flowAPI.experiments.enabled(
    'specs.bookings.refactorCalendarState',
  );

  const getServiceLocationIds = (state: CalendarState) => {
    const service = isRefactorCalendarStateEnabled
      ? state?.availableServices[0]
      : state?.selectedService;
    return service?.locations
      ?.map((location) => location?.businessLocation?.id)
      .filter((locationId) => locationId !== undefined);
  };
  const getServiceStaffIds = (state: CalendarState) => {
    const service = isRefactorCalendarStateEnabled
      ? state?.availableServices[0]
      : state?.selectedService;
    return service?.staffMembers?.map((staffMember) => staffMember.id);
  };

  const getServiceProperties = (state: CalendarState) => {
    const service = isRefactorCalendarStateEnabled
      ? state?.availableServices[0]
      : state?.selectedService;
    return JSON.stringify({
      locationIds: getServiceLocationIds(state),
      staffMemberIds: getServiceStaffIds(state),
      paymentOptions: service?.payment?.offeredAs,
      connectedSolutions: [],
    });
  };

  const getSelectedFilters = (state: CalendarState) =>
    JSON.stringify(state.filterOptions);

  const mapStateToDefaultBiParams = (state: CalendarState) => {
    const service = isRefactorCalendarStateEnabled
      ? state?.availableServices[0]
      : state?.selectedService;
    return {
      serviceType: service?.info.type,
      serviceId: service?.id,
      selectedTimezone: state?.selectedTimezone,
      errorMessage: JSON.stringify(state?.calendarErrors),
      serviceProperties: getServiceProperties(state),
      selectedFilters: getSelectedFilters(state),
      selectedDate: state?.selectedDate,
      selectedRange: JSON.stringify(state?.selectedRange),
      bookingId: state?.rescheduleBookingDetails?.id,
    };
  };

  const updateDefaultBiParams = (state: CalendarState) => {
    viewerBiLogger?.updateDefaults(mapStateToDefaultBiParams(state));
  };

  viewerBiLogger?.updateDefaults({
    ...widgetDefaults,
    referralInfo: wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.REFERRAL,
    ),
    businessProperties: JSON.stringify({
      language: businessInfo?.language,
      countryCode: businessInfo?.countryCode,
    }),
    ...mapStateToDefaultBiParams(initialState),
    defaultDateAtFirstLoad: settings.get(settingsParams.initializeCalendarDate),
    layout: settings.get(settingsParams.calendarLayout),
  });

  return Object.assign(viewerBiLogger, {
    update: updateDefaultBiParams,
  }) as CalendarBiLogger;
}

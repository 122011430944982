import {
  isServiceOfferedAsPricingPlan,
  PaymentDtoMapper,
} from '@wix/bookings-uou-mappers';
import {
  OfferedAsType,
  ServicePayment,
  ServicePaymentDto,
} from '@wix/bookings-uou-types';

import { CalendarContext } from '../context/contextFactory';
import { CalendarState } from '../../components/BookingCalendar/controller';

export const PRICE_DESCRIPTION_DELIMITER = ' | ';

export const getPaymentDescription = ({
  state,
  context,
}: {
  state: CalendarState;
  context: CalendarContext;
}) => {
  const paymentDescription = [];
  const {
    businessInfo,
    experiments,
    settings,
    settingsParams,
    isPricingPlanInstalled: isServiceBookableWithPricingPlan,
  } = context;
  const { availableServices, selectedService } = state;
  const isRefactorCalendarStateEnabled = experiments.enabled(
    'specs.bookings.refactorCalendarState',
  );
  const payment = isRefactorCalendarStateEnabled
    ? availableServices[0].payment
    : selectedService!.payment;
  const regionalSettingsLocale = businessInfo!.regionalSettingsLocale!;
  const bookingDetailsPricingPlanText = settings.get(
    settingsParams.bookingDetailsPricingPlanText,
  );
  if (isOfferedAsOneTime(payment)) {
    const priceText = getPriceText(payment, regionalSettingsLocale);
    paymentDescription.push(priceText);
  }
  if (
    isServiceOfferedAsPricingPlan(payment, isServiceBookableWithPricingPlan)
  ) {
    paymentDescription.push(bookingDetailsPricingPlanText);
  }
  return (
    paymentDescription
      // remove empty items
      .filter((priceItem) => !!priceItem.trim())
      .join(PRICE_DESCRIPTION_DELIMITER)
  );
};

const isOfferedAsOneTime = (payment: ServicePayment) => {
  return payment.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0;
};

const getPriceText = (
  payment: ServicePayment,
  dateRegionalSettingsLocale: string,
) => {
  const paymentDto: ServicePaymentDto = payment.paymentDetails;
  const paymentDtoMapper = new PaymentDtoMapper(dateRegionalSettingsLocale);
  return paymentDtoMapper.priceText(paymentDto);
};
